.container {
    display: block;
    position: fixed;
    width: 100%;
    z-index: 10000;
    overflow: hidden;
}

.buttonGroup {
    position: absolute;
    right: 5%;
    top: 50px;
}

.downarrow {
    position: absolute;
    text-align: center;
    left: 40%;
    right: 40%;
    top: 85%;
    color: white;
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
}

.fadeout {
    animation: fadeout 1s forwards;
}

.fadein {
    animation: fadein 1s forwards;
}

.rotate {
    transform: rotate(90deg);
}

.name {
    color: white;
    font-family: 'Zilla Slab Highlight', cursive;
}

.navbar {
    display: flex;
    list-style-type: none;
    width: 45%;
    min-width: 600px;
    margin: auto;
    padding-right: 40px;
    text-align: center;
}

ul li {
    font-family: 'Alatsi', sans-serif;
    color: white;
    font-size: 130%;
    cursor: pointer;
    width: 100%;
}

.navbar a:hover {
    border-left: 3px solid white;
    border-right: 3px solid white;
    padding-left: 2px;
    margin-left: -5px;
    padding-right: 2px;
    margin-right: -5px;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
}

@keyframes fadeout {
    from {
        opacity: 100%;
    }
    to {
        opacity: 0%;
    }
}

@keyframes fadein {
    from {
        opacity: 0%;
    }
    to {
        opacity: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .navbar {
        display: flex;
        list-style-type: none;
        width: 45%;
        min-width: 300px;
        margin: auto;
        text-align: center;
        flex-wrap: wrap;
    }
    
    ul li {
        font-family: 'Alatsi', sans-serif;
        color: white;
        font-size: 130%;
        cursor: pointer;
        width: 100%;
        margin-bottom: 5%;
    }

    .container {
        background-size: cover;
        background-repeat: none;
    }
}