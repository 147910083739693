.main {
    display: grid;
    grid-template-columns: 16.66% 83.33%;
    grid-gap: 10px;
    padding: 10px;
    grid-auto-flow: row;
    width: 80%;
    margin: 0 auto;
}

span a {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    background-image: linear-gradient(
    	to right,
	    dodgerblue,
	    deepskyblue 50%,
	    black 50%);
    background-size: 200% 100%;
    background-position: 100%;
    -webkit-transition: all 0.3s cubic-bezier(0.000, 0.000, 0.230, 1);
    -moz-transition: all 0.3s cubic-bezier(0.000, 0.000, 0.230, 1);
    -ms-transition: all 0.3s cubic-bezier(0.000, 0.000, 0.230, 1);
    -o-transition: all 0.3s cubic-bezier(0.000, 0.000, 0.230, 1);
    transition: all 0.3s cubic-bezier(0.000, 0.000, 0.230, 1);
}

.main > span {
    font-family: 'Alata', sans-serif;
}

span a:hover {
    -webkit-transition: all 0.3s cubic-bezier(0.000, 0.000, 0.230, 1);
    -moz-transition: all 0.3s cubic-bezier(0.000, 0.000, 0.230, 1);
    -ms-transition: all 0.3s cubic-bezier(0.000, 0.000, 0.230, 1);
    -o-transition: all 0.3s cubic-bezier(0.000, 0.000, 0.230, 1);
    transition: all 0.3s cubic-bezier(0.000, 0.000, 0.230, 1);
    background-position: 0%;
}

.projectsList div {
    margin-bottom: 10%;
}

.about {
    position: sticky;
    top: 180px;
    align-self: flex-start;
    grid-row: 1;
    font-size: x-large;
}

.publications {
    position: sticky;
    top: 243px;
    align-self: flex-start;
    grid-row: 2;
    font-size: x-large;
}

.publicationList {
    margin-left: 10%;
}

.research {
    position: sticky;
    align-self: flex-start;
    grid-row: 3;
    font-size: x-large;
}

.researchup {
    animation: researchup 0.20s forwards;
}

.researchdown {
    animation: researchdown 0.20s forwards;
}

.researchList {
    margin-left: 10%;
}

.activeMain {
    animation: enlarge 0.20s forwards;
    transition: all 0.3s cubic-bezier(0.000, 0.000, 0.230, 1);
}

.activeSub {
    border-left: 2px solid dodgerblue;
    padding-left: 2px;
}

.inactive {
    animation: shrink 0.20s forwards;
}

.projects {
    position: sticky;
    align-self: flex-start;
    grid-row: 4;
    font-size: x-large;
}

.projectsList {
    margin-left: 10%;
}

.subheader {
    font-size: 12pt;
    margin-top: 8pt;
}

@keyframes enlarge {
    from {
        font-size: x-large;
    }
    to {
        font-size: 36px;
        background-position: 0%;
    }
}

@keyframes shrink {
    from {
        font-size: 36px;
    }
    to {
        font-size: x-large;
    }
}

@media only screen and (max-width: 1468px) {
    .main {
        width: 90%;
    }
}

@media only screen and (max-width: 1300px) {
    .main {
        width: 100%;
        grid-template-columns: 25% 75%;
    }
}

@media only screen and (max-width: 768px) {
    .main {
        grid-template-columns: auto;
        width: 90%;
    }

    .about {
        grid-row: auto;
        background-color: white;
        top: 110px;
        z-index: 10;
        width: 100%;
        text-align: center;
        opacity: 0.95;
        font-size: 120%;
    }

    .publications {
        grid-row: auto;
        background-color: white;
        top: 138px;
        z-index: 10;
        text-align: center;
        opacity: 0.95;
        font-size: 120%;
    }

    .research {
        grid-row: auto;
        background-color: white;
        top: 166px;
        z-index: 10;
        text-align: center;
        opacity: 0.95;
        font-size: 120%;
    }

    .projects {
        grid-row: auto;
        background-color: white;
        top: 194px;
        z-index: 10;
        text-align: center;
        opacity: 0.95;
        font-size: 120%;
    }

    .activeMain {
        animation: blue 0.2s forwards;
    }

    .inactive {
        animation: unblue 0.2s forwards;
    }

    @keyframes blue {
        from {
            background-position: 100%;
        }
        to {
            background-position: 0%;
        }
    }

    @keyframes unblue {
        from {
            background-position: 0%;
        }
        to {
            background-position: 100%;
        }
    }
}