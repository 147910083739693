.container {
    width: 100%;
	margin: 0 auto;
}

.main {
    display: grid;
    grid-template-columns: 16.66% 83.33%;
    grid-gap: 10px;
    padding: 10px;
    grid-auto-flow: row;
}

